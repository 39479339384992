@tailwind base;
@tailwind components;
@tailwind utilities;

@media print {
    .noprint {
        display: none;
    }

    .onlyprint {
        display: inline;
    }
}

input[type='text']:focus {
    box-shadow: none;
}

input[type='tel']:focus {
    box-shadow: none;
}

.rbc-current-time-indicator {
    display: none;
}
